.nav_link{
    color: #0073B4 !important;
    margin-right: 60px;
    text-decoration: none !important;
    font-weight: 500;
}
.active{
    color: #fff;
    border-bottom: 3px solid #0073B4 !important;
}

.get_started_btn{
    background-color: #0073B4 !important;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 20px;
}

.get_started_btn:hover{
    background-color: #fff !important;
    color: #0073B4;
    transition: all 0.5s ease;
}

.register_btn{
    background-color: #0073B4 !important;
    color: #fff;
    border: none;
    padding: 8px 30px;
    border-radius: 20px;
}

.register_btn:hover{
    background-color: #fff !important;
    color: #0073B4;
    transition: all 0.5s ease;
}

@media (max-width: 768px) {
    .nav_link{
        margin-bottom: 20px;
    }
}