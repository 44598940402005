.main-div{
    background-image: url(../../assets/main-section.jpg);
    width: 100%;
    background-size: cover;
}

.row-div h2{
    color: #fff;
    position: relative;
}

.row-div p {
    color: #fff;
}

.main-row-div {
    align-items: center;
    /* height: 80vh; */
    padding: 80px 0;
}

.row-div h2{
    font-size: 40px;
    font-weight: 900;
}
.row-div p{
    font-size: 20px;
}

.about_us_img{
    width: 60%;
}
.img-div{
    display: flex;
    justify-content: center;
}
.section-row{
    padding: 0;
    margin: 0;
}

.first_section_div{
    padding: 60px;
}

.first_section_text_div h1{
    color: #0073B4;
    font-weight: 900;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 30px;
}


.first_section_text_div span{
    color: #5DC1ED;
    font-weight: 900;
    position: relative;
    text-transform: uppercase;
}

.first_section_text_div p {
    color: #3D3F42;
    font-size: 16px;
    font-weight: 500;
}

.consulting_div h1{
    color: #0073B4;
    font-weight: 900;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.consulting_div span{
    color: #5DC1ED;
    font-weight: 900;
    position: relative;
    text-transform: uppercase;
}

.consulting_div p {
    color: #3D3F42;
    font-size: 16px;
    font-weight: 500;
}

.consulting_div h1::after{
    position: absolute;
    display: block;
    content: "";
    top: 54px;
    bottom: 0;
    left: 0;
    right: 70%;
    border-bottom: 4px solid #0073B4;
}

.consulting_div h1::before{
    position: absolute;
    display: block;
    content: "";
    top: 54px;
    bottom: 100px;
    left: 35%;
    right: 35%;
    border-bottom: 4px solid #5DC1ED;
}

.first_section_img_div p{
    color: #3D3F42;
    font-size: 16px;
    font-weight: 500;
}

.first_section_img_div img{
   width: 100%;
}

.about-div p{
    color: #3D3F42;
}

.read_more_btn{
    background-color: #0073B4 !important;
    color: #fff;
    border: none;
    padding: 5px 30px;
    border-radius: 20px;
}

.read_more_btn:hover{
    background-color: #fff !important;
    color: #0073B4;
    transition: all 0.5s ease;
}

.about-div h1{
    color: #0073B4;
    text-transform: uppercase;
}
.about-div span {
    color: #5DC1ED;
}

.about-div-p{
    color: #0073B4 !important;
}

.second_section{
    background-color: #D9DCDD !important;
    padding: 60px;
}


.second_section h1{
    text-align: center;
    text-transform: uppercase;
    color: #0073B4;
    padding-top: 20px;
    position: relative;
    margin-bottom: 50px;
}

.second_section span{
    color: #5DC1ED;
}

.third_section_div p{
    color: #fff;
}

.service_section_div{
    height: 400px;
    border-radius: 60px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #f7f7f7;
    box-shadow: 0 20px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.service_section_div p{
    color: #3D3F42;
}

.service_section_div h5{
    color: #0073B4;
}

.third_section{
    background-image: url(../../assets/banner1.png);
    width: 100%;
    background-size: cover;
    padding: 60px 0;
    margin-left: 0;
}

.third_section_img img {
    width: 30%;
    margin-right: 10px;
} 


.fourth_section{
    height: 100%;
    padding: 50px 0;
}
.fourth_section h1{
    text-align: center;
    text-transform: uppercase;
    color: #0073B4;
    padding: 20px 0;
    margin-bottom: 30px;
}

.star_icon{
    color: yellow;
    font-size: 25px;
    text-align: center;
}


.row-div h2::after{
    position: absolute;
  display: block;
  content: "";
  top: 100px;
  bottom: 0;
  left: 0;
  right: 70%;
  border-bottom: 4px solid #0073B4;
}

.row-div h2::before{
    position: absolute;
  display: block;
  content: "";
  top: 100px;
  bottom: 0;
  left: 40%;
  right: 20%;
  border-bottom: 4px solid #5DC1ED;
}

.first_section_text_div h1::after{
 position: absolute;
  display: block;
  content: "";
  top: 100px;
  bottom: 0;
  left: 0;
  right: 70%;
  border-bottom: 4px solid #0073B4;
}

.first_section_text_div h1::before{
    position: absolute;
  display: block;
  content: "";
  top: 100px;
  bottom: 100px;
  left: 35%;
  right: 35%;
  border-bottom: 4px solid #5DC1ED;
   }

.second_section h1::after{
  position: absolute;
  display: block;
  content: "";
  top: 75px;
  bottom: 0;
  left: 41%;
  right: 51%;
  border-bottom: 4px solid #0073B4;
}

.second_section h1::before{
    position: absolute;
    display: block;
    content: "";
    top: 75px;
    bottom: 100px;
    left: 50%;
    right: 41%;
    border-bottom: 4px solid #5DC1ED;
}

@media (max-width: 768px) {
    .consulting_div h1::after{
        display: none;
    }

    .consulting_div h1::before{
        display: none;
    }

    .second_section h1::before{
        display: none;
    }

    .second_section h1::after{
        display: none;
    }

    .first_section_text_div h1::before {
    display: none;
    }

    .first_section_text_div h1::after {
        display: none;
    }

    .row-div h2::before{
        display: none;
    }
    .row-div h2::after{
        display: none;
    }

}